@import '~antd/dist/antd.less';
@import '../../../../global/assets/theme/var.less';

//font-color
@grey-0: #000;
@grey-3: #333;
@grey-6: #666;
@grey-9: #999;
@grey-c: #ccc;
@grey-e: #eee;
@default-white:#fff;
@white-f5:#f5f5f5;

// font-size
@fs-12: 12px;
@fs-14: 14px;
@fs-16: 16px;
@fs-18: 18px;
@fs-20: 20px;
@fs-24: 24px;
@fs-28: 28px;
@fs-32: 32px;

// line-height
@lh-12: 15px;
@lh-14: 15px;
@lh-16: 21px;
@lh-18: 24px;
@lh-20: 27px;
@lh-24: 33px;
@lh-28: 39px;
@lh-32: 45px;

// font-weight
@lighter: lighter;
@bold: bold;
@bolder: bolder;

// border-radius
@radius: 4px;

@interval: 8px;

@default-padding:24px;


.primary {
  &-fc {
    color: @primary-color;
  }

  &-bc {
    background-color: @primary-color;
  }
}

@import "/src/base.less";

.resume-item {
  display: flex;
  padding-bottom: 16px;
  padding-top: 24px;
  border-bottom: 1px solid #eeeeee;
  justify-content: space-between;
  align-items: center;
  color: @grey-3;

  &-user {
    display: flex;
  }

  &-info {
    display: flex;
    color: @grey-3;
    min-width: 400px;
    flex: 0 0 auto;

    &-left {
      width: 58px;
      height: 58px;
      border-radius: 4px;
      overflow: hidden;
      margin-right: 16px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }


      &-sex {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.39);
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & div {
        padding-right: 16px;
        display: flex;
      }

      &-name {
        font-size: @fs-18;
        font-weight: @bolder;
      }

      &-salary {
        font-size: @fs-18;
        color: #fd813e;
        font-weight: @bolder;
      }

      &-jobName {
        font-size: @fs-14;
        width: 130px;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
      }

      &-comprehensive {
        width: 350px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &-date {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-width: 280px;
    white-space: no-warp;
  }

  &-remark {
    width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-status {
    flex: 0 0 auto;

    span {
      flex: 0 0 auto;
      border-radius: 4px;
      display: inline-block;
      padding: 0 10px;
      font-size: 16px;
      height: 32px;
    }

    .processed {
      border: 1px solid #1890ff;
      color: #1890ff;
    }

    .pending {
      border: 1px solid #fc0e0e;
      color: #fc0e0e;
    }

    .expired {
      border: 1px solid green;
      color: green;
    }

    .overdue {
      border: 1px solid @grey-9;
      color: @grey-9;
    }
  }
}