@import '~antd/dist/antd.less';
@import '../../../global/assets/theme/var.less';

//font-color
@grey-0: #000;
@grey-3: #333;
@grey-6: #666;
@grey-9: #999;
@grey-c: #ccc;
@grey-e: #eee;
@default-white:#fff;
@white-f5:#f5f5f5;

// font-size
@fs-12: 12px;
@fs-14: 14px;
@fs-16: 16px;
@fs-18: 18px;
@fs-20: 20px;
@fs-24: 24px;
@fs-28: 28px;
@fs-32: 32px;

// line-height
@lh-12: 15px;
@lh-14: 15px;
@lh-16: 21px;
@lh-18: 24px;
@lh-20: 27px;
@lh-24: 33px;
@lh-28: 39px;
@lh-32: 45px;

// font-weight
@lighter: lighter;
@bold: bold;
@bolder: bolder;

// border-radius
@radius: 4px;

@interval: 8px;

@default-padding:24px;


.primary {
  &-fc {
    color: @primary-color;
  }

  &-bc {
    background-color: @primary-color;
  }
}

@import "/src/base.less";

.page-resume {
  min-width: 800px;
  padding: 24px;
  &-button-group {
    display: flex;

    &-primary,
    &-download {
      background-color: #0e79fd;
      width: 106px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: @fs-14;
      cursor: pointer;
      border-radius: 4px;

      & svg {
        display: block;
        margin-right: 9px;
      }
    }

    &-download {
      background-color: #fff;
      border: 1px #0e79fd solid;
      color: #0e79fd;
      border-radius: 4px;
    }

    &>div {
      margin-left: 32px;
    }
  }
  &-filter {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;

    &-button {
      background-color: #0e79fd;
      width: 106px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: @fs-14;
      cursor: pointer;
      border-radius: 4px;

      & svg {
        display: block;
        margin-right: 9px;
      }
    }

    &-search {
      display: flex;
    }

    &-params {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 20px;

      .ant-form-item {
        margin-bottom: 16px;
      }
    }
  }

  &-list {
    min-height: 500px;
    margin-bottom: 20px;
    &-header {
      display: flex;
      justify-content: space-between;

      &>div {
        margin-right: 10px;
      }
    }
  }

  &-pagination {
    display: flex;
    flex-direction: row-reverse;
  }
}

.resume-upload {
  width: 630px;
  height: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;

  &-context {
    height: 100%;
    width: 100%;
    margin-bottom: 36px;

    svg {
      width: 64px;
      height: 64px;
      margin-bottom: 27px;
    }

    &-message {
      margin-bottom: 40px;
      color: @grey-6;
      font-size: @fs-16;
      font-weight: @bold;
    }
  }

  &-close {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
  }

  &-info {
    color: @grey-3;
    font-size: @fs-14;

    &-item {
      margin-bottom: 16px;

      span {
        color: #0e79fd;
      }
    }

    &-button {
      margin-top: 16px;
      display: flex;
      flex-direction: row-reverse;
    }
  }
}

.page-resume-filter-common {
  width: 160px !important;
  border-radius: 2px !important;
  margin-right: 32px !important;
}
.ant-checkbox-group {
  .ant-checkbox-group-item {
    width: 100%;
    display: flex;

    .ant-checkbox {
      margin-right: 10px;
    }
  }

  .ant-checkbox-group-item span:nth-child(2) {
    flex: 1;
  }
}
.resume-obtain {
  padding-left: 24px;

  &-checked {
    &-count {
      color: #0E79FD;
    }

    font-size: 14px;
    margin-bottom: 32px;
  }

}
