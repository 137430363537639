@import '~antd/dist/antd.less';
@import '../../../global/assets/theme/var.less';

//font-color
@grey-0: #000;
@grey-3: #333;
@grey-6: #666;
@grey-9: #999;
@grey-c: #ccc;
@grey-e: #eee;
@default-white:#fff;
@white-f5:#f5f5f5;

// font-size
@fs-12: 12px;
@fs-14: 14px;
@fs-16: 16px;
@fs-18: 18px;
@fs-20: 20px;
@fs-24: 24px;
@fs-28: 28px;
@fs-32: 32px;

// line-height
@lh-12: 15px;
@lh-14: 15px;
@lh-16: 21px;
@lh-18: 24px;
@lh-20: 27px;
@lh-24: 33px;
@lh-28: 39px;
@lh-32: 45px;

// font-weight
@lighter: lighter;
@bold: bold;
@bolder: bolder;

// border-radius
@radius: 4px;

@interval: 8px;

@default-padding:24px;


.primary {
  &-fc {
    color: @primary-color;
  }

  &-bc {
    background-color: @primary-color;
  }
}

.icon{
  display: inline-block;
}