@import '~antd/dist/antd.less';
@import '../../../../global/assets/theme/var.less';

//font-color
@grey-0: #000;
@grey-3: #333;
@grey-6: #666;
@grey-9: #999;
@grey-c: #ccc;
@grey-e: #eee;
@default-white:#fff;
@white-f5:#f5f5f5;

// font-size
@fs-12: 12px;
@fs-14: 14px;
@fs-16: 16px;
@fs-18: 18px;
@fs-20: 20px;
@fs-24: 24px;
@fs-28: 28px;
@fs-32: 32px;

// line-height
@lh-12: 15px;
@lh-14: 15px;
@lh-16: 21px;
@lh-18: 24px;
@lh-20: 27px;
@lh-24: 33px;
@lh-28: 39px;
@lh-32: 45px;

// font-weight
@lighter: lighter;
@bold: bold;
@bolder: bolder;

// border-radius
@radius: 4px;

@interval: 8px;

@default-padding:24px;


.primary {
  &-fc {
    color: @primary-color;
  }

  &-bc {
    background-color: @primary-color;
  }
}

.resume-detail {
  display: flex;
  // position: relative;

  &-tool {
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28px 10px 0 10px;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: @fs-12;
      margin-bottom: 30px;
      color: @grey-6;
      white-space: nowrap;
      cursor: pointer;

      & svg {
        display: block;
        margin-bottom: 12px;
      }
    }
  }

  &-info {
    // flex: 1;
    overflow: auto;
    height: 100vh;
    width: 100%;

    &-tool {
      position: absolute;
      left: -400px;
      top: 0;
      box-sizing: border-box;

      &-title {
        font-size: @fs-16;
        color: @grey-3;
        font-weight: @bold;
        margin-bottom: 18px;
      }

      &-project,
      &-remark,
      &-state,
      &-interview,
      &-in-project,
      &-status,
      &-work {
        width: 400px;
        padding: 20px 24px;
        background-color: #fff;
        min-height: 400px;

        &-select {
          margin-bottom: 24px;
        }
      }

      &-project {
        &-list {
          padding-top: 10px;
          display: flex;
          flex-direction: column;
          margin-bottom: 26px;
          height: 282px;
          overflow: auto;
        }

        &-item {
          display: flex;
          border-bottom: 1px solid #eeeeee;
          align-items: center;
          padding: 6px 0;

          &-title {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            &-job {
              font-weight: @bolder;
              font-size: @fs-16;
              color: @grey-3;
              width: 165px;
              margin-right: 16px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &-type {
              color: #3b95f2;
              font-size: @fs-14;
              font-weight: @bolder;
            }
          }

          &-company {
            color: @grey-6;
            font-size: @fs-12;
          }
        }

        &-button {
          display: flex;
          flex-direction: row-reverse;

          & button {
            margin-right: 12px;
          }
        }
      }

      &-remark {
        &-list {
          display: flex;
          flex-direction: column;
          margin-bottom: 26px;
          height: 200px;
          padding-top: 10px;
          overflow: auto;

          &-item {
            color: @grey-3;
            font-size: @fs-14;
          }
        }

        &-create {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;

          & button {
            width: 214px;
            height: 36px;
          }
        }
      }

      &-interview {
        width: 400px;
        color: @grey-3;
        font-size: @fs-14;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-time {
          margin-bottom: 25px;
        }

        &-address {
          display: flex;

          &-title {
            flex-shrink: 0;
          }
        }

        &-button {
          display: flex;
          flex-direction: row-reverse;
        }
      }

      &-status {
        height: 300px;
        min-height: 300px;

        &-flex {
          margin-top: 40px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
        }

      }

      &-work {
        &-round {
          width: 10px;
          height: 10px;
          background-color: #fff;
          border: 2px solid transparent;
          border-radius: 100px;
          color: #0E79FD;
          border-color: #0E79FD;
          margin-right: 10px;

          &-item {
            display: flex;
            align-items: center;
            margin-right: 20px;
          }
        }

        &-list {
          overflow: auto;
          padding-top: 10px;
          height: 250px;
        }
      }
    }

    &-header {
      padding-left: 20px;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      font-size: @fs-14;
      // font-weight: @lighter;
      color: @grey-3;

      &-steps {
        width: 92%;
        margin-bottom: 20px;

        .resume-card-header-title {
          font-weight: bolder;
        }

        .operate {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 14px;
          height: 36px;
          // background-color: red;

          .name {
            display: flex;
            align-items: center;
          }

          .btn-operate {
            .first-btn {
              margin: 0 30px;
            }
          }
        }
      }

      &-title {
        font-size: @fs-16;
        color: @grey-3;
        font-weight: @bold;
        margin-bottom: 27px;
      }

      &-state {
        display: flex;
        margin-bottom: 34px;
        align-items: center;

        &-box {
          border-radius: 4px;
          border: 1px solid;
          font-size: @fs-16;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px;
          min-width: 68px;
        }
      }

      &-remark {
        // display: flex;
        margin-bottom: 16px;

        &-tips {
          white-space: no-warp;
          display: flex;
        }
      }

      &-pull {
        margin-bottom: 16px;

        &-detail {
          display: flex;
        }

      }

      &-date {
        display: flex;
        flex-wrap: wrap;

        & div {
          display: flex;
        }

        &-start {
          margin: 0 20px 10px 0;
        }
      }

      padding-bottom: 16px;
      border-bottom: 1px solid #eeeeee;
    }

    &-user {
      display: flex;
      padding: 36px 0px;
      border-bottom: 1px solid #eeeeee;

      &-left {
        width: 58px;
        height: 58px;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        margin-right: 16px;

        & img {
          width: 100%;
          height: 100%;
        }

        &-sex {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          position: absolute;

          bottom: 0;
          right: 0;
          background: rgba(255, 255, 255, 0.39);
        }
      }

      &-right {
        font-size: @fs-14;
        color: @grey-3;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-name {
          display: flex;
          font-weight: @bolder;
          font-size: @fs-18;

          &-essential {
            display: flex;
            align-items: flex-end;
            font-size: @fs-14;
            margin-left: 20px;
            font-weight: 400;
          }
        }

        &-contact {
          display: flex;

          &>div {
            margin-right: 20px;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    &-want {
      &-content {
        &-common {
          display: flex;
        }
      }
    }

    &-intention {
      &-skill {
        display: flex;
        align-items: flex-start;
        margin-bottom: 19px;

        &-tags {
          display: flex;
          align-items: center;
        }

        &-tag {
          text-align: center;
          font-size: @fs-12;
          color: #0e79fd;
          line-height: 17px;
          // font-weight: @lighter;
          border: 1px solid #0e79fd;
          border-radius: 13px;
          margin-right: 10px;
          min-width: 68px;
          cursor: pointer;
        }
      }

      &-introduce {
        display: flex;
        align-items: flex-start;
      }
    }

    &-edu {
      &-item {
        &-school {
          display: flex;
          align-items: flex-end;
          margin-bottom: 20px;

          &-name {
            color: @grey-3;
            font-size: @fs-18;
            margin-right: 10px;
            font-weight: @bold;
          }

          &-time {
            color: #0e79fd;
            font-size: @fs-14;
            // font-weight: @lighter;
          }
        }

        &-edu {
          display: flex;

          &>div {
            display: flex;
          }
        }
      }
    }

    &-company {
      color: @grey-3;

      &-job {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
      }

      &-jobDetail {
        display: flex;
        align-items: flex-start;
      }
    }

    &-project {
      &-item {
        &-content {
          display: flex;
          align-items: flex-start;
        }
      }
    }
  }

  &-common {
    display: flex;
    align-items: center;

    &-title {
      white-space: nowrap;
      color: @grey-6;
    }

    &-blue {
      width: 5px;
      height: 5px;
      background: #0e79fd;
      border-radius: 50%;
      margin-right: 7px;
    }

    &-card-title {
      display: flex;
      align-items: flex-end;
      margin-bottom: 20px;

      &-name {
        color: @grey-3;
        font-size: @fs-18;
        margin-right: 10px;
        font-weight: @bold;
      }

      &-time {
        color: #0e79fd;
        font-size: @fs-14;
        // font-weight: @lighter;
      }
    }
  }
}

.resume-card {
  padding: 32px 0px;
  border-bottom: 1px solid #eeeeee;

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &-blue {
      width: 2px;
      height: 14px;
      background: #0e79fd;
      border: 1px solid #0e79fd;
      opacity: 1;
      margin-right: 8px;
    }

    &-title {
      color: @grey-3;
      font-size: @fs-16;
      font-weight: @bolder;
    }
  }
}

.tool {
  position: relative;
}

.ant-drawer .ant-drawer-content {
  overflow: visible !important;
}

.ant-drawer-body {
  padding: 0 !important;
  overflow: visible;
}

.project-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: @fs-16;
  padding-top: 20px;
}

.ant-timeline-item-last {
  padding-bottom: 0 !important;
}

.mar-top {
  margin-top: 20px;
}